import type { WidgetConfigTypeDefs } from '@gql/schema';
import { getBreakpointFromWidth, MediaType } from '../assets/scss/variables';
import type { Nullable } from '@models/CustomUtilityTypes';

export enum State {
  CLOSED,
  OPENED,
}

export enum ListType {
  LIST = 'list',
  GALLERY = 'gallery',
}

const DEFAULT_PAGE_SIZE = 10;

export enum LoadingState {
  FILTERING = 'filtering',
  FETCHING = 'fetching',
  LOADED = 'loaded',
}

export type GlobalStore = {
  modalState: State;
  pageSize: number;
  listType: ListType | undefined;
  mapState: State;
  containerWidth: number;
  mediaType: MediaType;
  loadingState: LoadingState;
};

export const useGlobalStore = defineStore('globalStore', () => {
  const state = reactive<GlobalStore>({
    containerWidth: 0,
    listType: undefined,
    mapState: State.OPENED,
    mediaType: MediaType.MD,
    modalState: State.CLOSED,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingState: LoadingState.LOADED,
  });

  watchEffect(() => {
    state.mediaType = getBreakpointFromWidth(state.containerWidth);
  });

  const setModalState = (newState: State): void => {
    state.modalState = newState;
  };

  const setMapState = (newState: State): void => {
    state.mapState = newState;
  };

  const setContainerWidth = (width: number): void => {
    state.containerWidth = width;
  };

  const setLoadingState = (newState: LoadingState): void => {
    state.loadingState = newState;
  };

  const toggleModalState = (): void => {
    state.modalState =
      state.modalState === State.OPENED ? State.CLOSED : State.OPENED;
  };

  const toggleMapState = (): void => {
    state.mapState =
      state.mapState === State.OPENED ? State.CLOSED : State.OPENED;
  };

  const increasePageSize = (size: number): void => {
    if (!Number.isInteger(size) || size <= 0) {
      console.error('Size must be a positive integer');
      return;
    }
    state.pageSize += size;
  };

  const resetPageSize = (): void => {
    state.pageSize = DEFAULT_PAGE_SIZE;
  };

  const setListType = (
    widgetTypeConfig: Nullable<WidgetConfigTypeDefs>,
    type?: GlobalStore['listType']
  ): void => {
    if (!widgetTypeConfig && !type) {
      return;
    }

    if (!isEmpty(type)) {
      state.listType = type!;
    } else if (widgetTypeConfig && isEmpty(state.listType)) {
      state.listType =
        widgetTypeConfig.entryViewType?.name === 'entrypage'
          ? ListType.LIST
          : (widgetTypeConfig.entryViewType?.name as ListType);
    }
  };

  const toggleListType = (): void => {
    state.listType =
      state.listType === ListType.LIST ? ListType.GALLERY : ListType.LIST;
  };

  return {
    // (reactive) state itself
    state,

    // actions
    setModalState,
    setMapState,
    toggleMapState,
    setContainerWidth,
    toggleModalState,
    increasePageSize,
    resetPageSize,
    setListType,
    toggleListType,
    setLoadingState,
  };
});
