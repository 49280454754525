import type { ComputedRef, MaybeRef } from 'vue';
import type { FocalPoint } from '@models/BaseImage';

/**
 *
 * @param src absolute url to the image (or relative path to the image in the assets folder)
 * @param dimensions width x height string (e.g. "200x150")
 * @param focalPoint optional focal point with relative x and y position (0...1)
 * @param cacheBuster optional cache buster string
 */
export default (
  src: MaybeRef<string>,
  dimensions: MaybeRef<string>,
  focalPoint?: MaybeRef<FocalPoint>,
  cacheBuster?: MaybeRef<string>
): ComputedRef<string> => {
  return computed(() => {
    const { imgproxy } = useImgProxy();
    const [width, height] = toValue(dimensions)?.split('x') ?? [0, 0];

    return imgproxy(
      buildSourceToEncode(toValue(src)),
      `${width || 0}x${height || 0}`,
      toValue(focalPoint)?.x,
      toValue(focalPoint)?.y,
      toValue(cacheBuster)
    );
  });
};

const buildSourceToEncode = (src: string): string => {
  if (isNuxtAsset(src)) {
    const config = useRuntimeConfig();
    return `${config.public.storybookAssetsBase}${src}`;
  }
  return src;
};

const isNuxtAsset = (src: string): boolean => {
  return src.startsWith('assets');
};
