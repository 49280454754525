import type { RawWidgetConfigFragment } from '@gql/fragments/__generated/RawWidgetConfig';
import type { AppearanceFilter } from '@gql/schema';
import type { Nullable } from '@models/CustomUtilityTypes';

export default (
  widgetConfig: MaybeRefOrGetter<Nullable<RawWidgetConfigFragment>>
): Ref<AppearanceFilter | null> => {
  return computed(() =>
    rawBuildAppearanceFilterFromWidgetConfig(toValue(widgetConfig))
  );
};
