export const useBookmarkStore = defineStore('bookmarkStore', () => {
  const { query } = useRoute();

  const bookmarks = useLocalStorage<string[]>('bookmarks', [], {
    listenToStorageChanges: true,
  });
  //const bookmarks = ref<string[]>([]);
  const bookmarksCount = computed(() => toValue(bookmarks).length);

  const addBookmark = (bookmark: string) => {
    if (!bookmarks.value.includes(bookmark)) {
      bookmarks.value.push(bookmark);
    }
  };

  const removeBookmark = (bookmark: string) => {
    bookmarks.value = bookmarks.value.filter((b) => b !== bookmark);
  };

  const contains = (bookmark: string) => {
    return bookmarks.value.includes(bookmark);
  };

  const initializeFromRoute = (): void => {
    const bookmarksQueryParam = query.bookmarks;

    if (!isEmpty(bookmarksQueryParam)) {
      if (Array.isArray(bookmarksQueryParam)) {
        bookmarks.value = bookmarksQueryParam
          .filter((bookmark): bookmark is string => bookmark !== null)
          .map((bookmark) => bookmark.trim());
      } else {
        bookmarks.value = bookmarksQueryParam!
          .split(',')
          .map((bookmark: string) => bookmark.trim());
      }
    }
  };

  return {
    bookmarks,
    bookmarksCount,
    contains,
    addBookmark,
    removeBookmark,
    initializeFromRoute,
  };
});
