<template>
  <picture>
    <source
      v-for="breakpoint in breakpointsWithSrcSet"
      :key="breakpoint.media"
      :media="breakpoint.media"
      :srcset="breakpoint.srcset"
    />
    <img v-bind="$attrs" :src="source" />
  </picture>
</template>

<script setup lang="ts">
import type { BaseProxyImg } from '@models/BaseProxyImage';

// allow lazyload/class or other attributes to be passed through to <img>-tag
defineOptions({
  inheritAttrs: false,
});

type Breakpoint = { media: string; dimensions: string };
type SrcSet = { srcset: string };

const props = defineProps<
  BaseProxyImg & {
    breakpoints: Breakpoint[];
    cacheBuster?: string;
  }
>();

const breakpointsWithSrcSet = useArrayMap(
  () => props.breakpoints,
  (breakpoint: Breakpoint): Breakpoint & SrcSet => {
    return {
      ...breakpoint,
      srcset: toValue(
        buildImgProxyUrl(
          props.src,
          breakpoint.dimensions,
          props.focalPoint,
          props.cacheBuster
        )
      ),
    };
  }
);

const source = computed(
  () => useArrayFirst(breakpointsWithSrcSet)?.value?.srcset
);
</script>
